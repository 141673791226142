import React from 'react';

import { UseToastProvider } from '@motorway/motorway-storybook-cra';

import { BreakpointProvider } from '../../../../../components/misc/Breakpoints/Breakpoints';
import { FullBleedImage } from '../../Components/FullBleedImage/FullBleedImage';
import { GuidesSection as Guides } from '../../Components/GuidesSection/GuidesSection';
import { Hero as HomepageHero } from '../../Components/Hero/Hero';
import { HomepageFAQ } from '../../Components/HomepageFAQ/HomepageFAQ';
import { HomepagePartnerLogos } from '../../Components/HomepagePartnerLogos/HomepagePartnerLogos';
import { HowItWorks } from '../../Components/HowItWorks/HowItWorks';
import { NewsAndTools } from '../../Components/NewsAndTools/NewsAndTools';
import { Reviews } from '../../Components/Reviews/Reviews';
import { SellerStories } from '../../Components/SellerStories/SellerStories';
import { SocialProof } from '../../Components/SocialProof/SocialProof';

import styles from './Homepage.scss';

const Homepage = () => (
  <UseToastProvider>
    <BreakpointProvider>
      <HomepageHero />
      <HowItWorks className={styles.howItWorks} />
      <SellerStories className={styles.sellerStories} />
      <FullBleedImage />
      <SocialProof className={styles.socialProof} />
      <Reviews className={styles.reviews} />
      <HomepagePartnerLogos className={styles.partnerLogos} />
      <NewsAndTools className={styles.newsAndTools} />
      <HomepageFAQ className={styles.faq} />
      <Guides />
    </BreakpointProvider>
  </UseToastProvider>
);

export default Homepage;
