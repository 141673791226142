import React from 'react';
import cx from 'classnames';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import { SellerStory } from '../../../../../storybook/components/local/SellerStory/SellerStory';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import { RoundedSection } from '../../../common/RoundedSection/RoundedSection';
import { Heading } from '../Heading/Heading';

import { sellerStoriesConfig } from './SellerStories.config';

import styles from './SellerStories.scss';

type Props = {
  readonly className?: string;
}

export const SellerStories = ({ className }: Props) => {
  const onRefTrigger = () => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.SELLER_STORIES);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  const { stories, subtitle, title } = sellerStoriesConfig;

  return (
    <RoundedSection withBottomLedge theme="primary-mid-light-yellow">
      <div className={cx(styles.component, className)}>
        <Heading
          center
          {...{ subtitle, title }}
        />
        <div
          ref={(ref) => setRef(ref)}
          className={styles.stories}
          data-testid='sellerStories'
        >
          {
            stories.map(({
              details,
              image: { avif, png, webp },
              label,
              quote,
            }, index) => (
              <SellerStory
                key={`seller-story-${index + 1}`}
                data={{
                  details,
                  image: { avif, png, webp },
                  label,
                  quote,
                }}
              />
            ))
          }
        </div>
      </div>
    </RoundedSection>
  );
};
