import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import cypressIds from 'CypressId';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';
import { applyCypressData } from 'Utilities/index';

import step1Avif from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_1.avif';
import step1Jpg from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_1.jpg';
import step1WebP from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_1.webp';
import step2Avif from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_2.avif';
import step2Jpg from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_2.jpg';
import step2WebP from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_2.webp';
import step3Avif from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_3.avif';
import step3Jpg from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_3.jpg';
import step3WebP from '../../../../../../client/assets_seller/howitworks/homepage/2025/step_3.webp';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import LocalTexts from '../../HomepageTexts.json';
import { Heading } from '../Heading/Heading';

import { Step, StepProps } from './SubComponents/Step/Step';

import styles from './HowItWorks.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

const texts = {
  highlightedWord: t('howItWorks.highlightedWord'),
  subtitle: t('howItWorks.subtitle'),
  title: t('howItWorks.title'),
};

const steps: StepProps[] = [
  {
    description: t('howItWorks.step1.desc'),
    image: {
      alt: t('howItWorks.step1.alt'),
      avif: step1Avif,
      height: 378,
      jpg: step1Jpg,
      webp: step1WebP,
      width: 378,
    },
    title: t('howItWorks.step1.title'),
  },
  {
    description: t('howItWorks.step2.desc'),
    image: {
      alt: t('howItWorks.step2.alt'),
      avif: step2Avif,
      height: 378,
      jpg: step2Jpg,
      webp: step2WebP,
      width: 378,
    },
    title: t('howItWorks.step2.title'),
  },
  {
    description: t('howItWorks.step3.desc'),
    image: {
      alt: t('howItWorks.step3.alt'),
      avif: step3Avif,
      height: 378,
      jpg: step3Jpg,
      webp: step3WebP,
      width: 378,
    },
    title: t('howItWorks.step3.title'),
  },
];

type Props = {
  readonly className?: string;
  isHomepage?: boolean;
}

export const HowItWorks = ({ className }: Props) => {
  const eventLabels = steps.map((_, index) => `Step ${index + 1}`);
  const onRefTrigger = ((eventLabel?: string) => {
    HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.HOW_IT_WORKS, eventLabel);
  });
  const { setRef } = usePageRefEvents({ eventLabels, onRefTrigger });

  return (
    <Content className={cx(styles.component, className)} {...applyCypressData(cypressIds.sections.howItWorks)}>
      <Heading
        center
        highlightedWord={texts.highlightedWord}
        subtitle={texts.subtitle}
        title={texts.title}
      />
      <div className={styles.steps}>
        {steps.map((props, i) => <Step key={props.title} ref={(ref) => setRef(ref, i)} {...props} />)}
      </div>
    </Content>
  );
};
