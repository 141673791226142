import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import { ArrowFullRightIcon, IconText } from '@motorway/mw-highway-code';

import styles from './NewsAndToolsCard.scss';

export type NewsAndToolsCardProps = {
  cta: string;
  img?: {
    alt: string;
    src: {
      avif: string;
      png: string;
      webp: string;
    };
  };
  label: string;
  link: string;
  onClick: () => void;
  openInNewTab?: boolean;
  tag?: string;
  theme?: 'blue' | 'green' | 'purple';
  title: string;
  trackingLink?: string;
};

type NewsAndToolsContentWrapperProps = {
  children: React.ReactNode;
  rel: string;
  target: string;
}

const isExternalLink = (r: string) => /^https?:\/\//.test(r);

const NewsAndToolsContentWrapper = ({
  children,
  link,
  onClick,
  rel,
  target,
}: Pick<NewsAndToolsCardProps, 'link' | 'onClick'> & NewsAndToolsContentWrapperProps) => (<>
  {
    isExternalLink(link)
      ? (
        <a
          {...{ onClick, rel, target }}
          data-thc-style-override
          href={link}
        >
          {children}
        </a>
      ) : (
        <Link
          {...{ onClick, rel, target }}
          data-thc-style-override
          to={link}
        >
          {children}
        </Link>
      )
  }
</>);

export const NewsAndToolsCard = forwardRef<HTMLDivElement, NewsAndToolsCardProps>(
  ({ cta, img, link, onClick, openInNewTab, tag, theme = 'blue', title }, ref) => (
    <div ref={ref} className={styles.component} data-testid='newsAndToolsCard'>
      <NewsAndToolsContentWrapper
        rel='noreferrer noopener'
        target={openInNewTab ? '_blank' : '_self'}
        {...{ link, onClick, openInNewTab }}
      >
        {img && (
          <picture>
            <source srcSet={img?.src.avif} type="image/avif" />
            <source srcSet={img?.src.webp} type="image/webp" />
            <img
              alt={img?.alt}
              height={270}
              loading='lazy'
              src={img?.src.png}
              width={316} />
          </picture>
        )}
        <div className={styles.content}>
          {tag && <span className={styles.tag} data-theme={theme}>{tag}</span>}
          <h3>{title}</h3>
        </div>
        <IconText
          data-thc-icontext
          reverse
          icon={ArrowFullRightIcon}
          text={<span>{cta}</span>}
          variant="xsmall--regular"
        />
      </NewsAndToolsContentWrapper>
    </div>
  ),
);
