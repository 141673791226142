import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { GA_TAGS } from 'Utilities/analytics';
import { SP_FOOTER_EVENTS } from 'Utilities/analytics/analyticsFooter';
import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import imgAvif from '../../../../../../client/assets_seller/homepage/2025-march-campaign.avif';
import imgJpg from '../../../../../../client/assets_seller/homepage/2025-march-campaign.jpg';
import imgWebp from '../../../../../../client/assets_seller/homepage/2025-march-campaign.webp';
import imgMobileAvif from '../../../../../../client/assets_seller/homepage/2025-march-campaign-mobile.avif';
import imgMobileWebp from '../../../../../../client/assets_seller/homepage/2025-march-campaign-mobile.webp';
import VrmChecker from '../../../../../components/vrm/VrmChecker/VrmChecker';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import { RoundedSection } from '../../../common/RoundedSection/RoundedSection';
import LocalTexts from '../../HomepageTexts.json';

import styles from './FullBleedImage.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

export const Texts = {
  buttonLabel: t('magic.cta'),
  description: t('magic.description'),
  imgAlt: t('magic.imgAlt'),
  title: t('magic.title'),
};

const callToActionProps = {
  ctaButtonLabel: Texts.buttonLabel,
  ctaTitle: Texts.title,
  description: Texts.description,
  imageAlt: Texts.imgAlt,
};

type Props = {
  readonly className?: string;
}

export const FullBleedImage = ({ className }: Props) => {
  // TODO - add correct SP tracking details
  const onRefTrigger = () =>
    HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.FULL_BLEED_IMAGE);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  return (
    <RoundedSection withTopLedge className={styles.section}>
      <div
        ref={(ref) => setRef(ref)}
        className={cx(className)}
        data-testid='fullBleedImage'
      >
        <Content className={styles.component}>
          <div className={styles.contentBlock}>
            <h3 className={styles.title} data-cy="claimSection">{callToActionProps.ctaTitle}</h3>
            <p className={cx('mw-i', styles.description)}>{callToActionProps.description}</p>

            <VrmChecker
              // @ts-expect-error this is correctly typed, despite the error
              analyticsEvents={{
                onInputFocus: GA_TAGS.FOOTER_VRM_INPUT_FOCUSED,
                vrmSubmittedSuccessfully: () => SP_FOOTER_EVENTS.FOOTER_VRM_CHECKER_SUBMITTED({
                  buttonLabel: callToActionProps.ctaButtonLabel,
                  label: '/',
                }),
              }}
              buttonProps={{
                className: styles.button,
                label: callToActionProps.ctaButtonLabel,
                showLabel: false,
              }}
              chevron={true}
              className={styles.form}
              e2eName="full bleed vrm checker"
              id="full-bleed-vrm"
              large={false}
              showLabel={false}
            />
          </div>
        </Content>

        {/* TODO - update to campaign imagery, and responsive images */}
        <picture className={styles.image}>
          <source media="(max-width: 744px)" srcSet={imgMobileAvif} type="image/avif" />
          <source media="(max-width: 744px)" srcSet={imgMobileWebp} type="image/webp" />
          <source srcSet={imgAvif} type="image/avif" />
          <source srcSet={imgWebp} type="image/webp" />
          <img
            alt={callToActionProps.imageAlt}
            className={styles.image}
            loading='lazy'
            src={imgJpg}
          />
        </picture>
        <picture className={cx(styles.image, styles.image2)}>
          <source srcSet={imgAvif} type="image/avif" />
          <source srcSet={imgWebp} type="image/webp" />
          <img
            alt={callToActionProps.imageAlt}
            className={styles.image}
            loading='lazy'
            src={imgJpg}
          />
        </picture>
      </div>
    </RoundedSection>
  );
};
