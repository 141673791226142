import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@motorway/mw-highway-code';

import styles from './SellerStory.module.scss';

type Props = {
  readonly data: {
    details: string;
    image: {
      avif: string;
      png: string;
      webp: string;
    };
    label: string;
    quote: string;
  };
};

export const SellerStory = ({ data }: Props) => {
  const { details, image, label, quote } = data;

  return (
    <div className={styles.component} data-testid='sellerStory'>
      <picture>
        <source srcSet={image.avif} type="image/avif" />
        <source srcSet={image.webp} type="image/webp" />
        <img
          alt={details}
          className={styles.thumbnail}
          loading='lazy'
          src={image.png}
        />
      </picture>
      <div className={styles.content}>
        <p className={styles.quote}>{quote}</p>
        <div className={styles.details}>
          <p>{details}</p>
          <Button as={Link} label={label} size="small" to="/stories" variant="secondary" />
        </div>
      </div>
    </div>
  );
};
