import styles from './TextWithHighlight.scss';

export type TextWithHighlightProps = {
  content: string;
  highlight: string;
}

export const TextWithHighlight = ({ content, highlight }: TextWithHighlightProps) => {
  const contentParts = content.split(highlight);

  return (
    <>
      <span>{contentParts[0]}</span>
      <em className={styles.highlight}>
        {highlight}
      </em>
      {contentParts[1] && <span>{contentParts[1]}</span>}
    </>
  );
};
