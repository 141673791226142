import { useContext, useEffect } from 'react';

import { OptimizelyContext } from '@optimizely/react-sdk';

import { ConfigContext } from 'Context/config';

import { FLAG } from 'Utilities/analytics/optimizely/constants';

export const flagVariants = ['off', 'on'] as const;

export const useHomeHeroFontExperiment = () => {
  const { optimizely } = useContext(OptimizelyContext);
  const { configState: { experimentDecisions } } = useContext(ConfigContext);
  const experimentVariant = experimentDecisions?.[FLAG.SWA_HOME_HERO_FONT] ?? flagVariants[0];

  useEffect(() => {
    optimizely?.onReady().then(() => {
      if (experimentDecisions?.[FLAG.SWA_HOME_HERO_FONT]) {
        optimizely.decide(FLAG.SWA_HOME_HERO_FONT);
      }
    });
  }, [experimentDecisions, optimizely]);

  return experimentVariant === 'on';
};
