import React, { useContext } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import cypressIds from 'CypressId';

import { ConfigContext } from 'Context/config';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';
import { applyCypressData } from 'Utilities/index';

import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import { RoundedSection } from '../../../common/RoundedSection/RoundedSection';
import RecentSales from '../../../fastlane/recentSales/RecentSales';
import LocalTexts from '../../HomepageTexts.json';
import { Heading } from '../Heading/Heading';

import styles from './SocialProof.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

const texts = {
  highlightedWord: t('socialProof.highlightedWord'),
  title: t('socialProof.title'),
};

type Props = {
  readonly className?: string;
}

export const SocialProof = ({ className }: Props) => {
  const onRefTrigger = () => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.SOCIAL_PROOF);
  const { setRef } = usePageRefEvents({ onRefTrigger });
  const { configState: { recentSales } } = useContext(ConfigContext);

  if (!recentSales?.length) {
    return null;
  }

  return (
    <RoundedSection className={cx(className)}>
      <div ref={(ref) => setRef(ref)} data-testid='socialProof'>
        <Content className={styles.headingWrapper} {...applyCypressData(cypressIds.sections.socialBlurb)}>
          <Heading
            center
            title={texts.title}
          />
        </Content>
        <RecentSales
          carouselClassName={styles.recentSalesCarouselWrapper}
          className={styles.recentSalesWrapper}
          showTitle={false}
        />
      </div>
    </RoundedSection>
  );
};
