import { MDText } from 'i18n-react';

import { APP_URL, YOUTUBE_EMBED_URL } from 'Utilities/urls';
import { GUIDES_SELL_MY_CAR_PATH } from 'Utilities/wordpress';

import appAvif from '../../../../../../client/assets_seller/homepage/download-app.avif';
import appJpg from '../../../../../../client/assets_seller/homepage/download-app.jpg';
import appWebp from '../../../../../../client/assets_seller/homepage/download-app.webp';
import guidesAvif from '../../../../../../client/assets_seller/homepage/guides.avif';
import guidesJpg from '../../../../../../client/assets_seller/homepage/guides.jpg';
import guidesWebp from '../../../../../../client/assets_seller/homepage/guides.webp';
import advertAvif from '../../../../../../client/assets_seller/homepage/motorway-tv-ad.avif';
import advertJpg from '../../../../../../client/assets_seller/homepage/motorway-tv-ad.jpg';
import advertWebp from '../../../../../../client/assets_seller/homepage/motorway-tv-ad.webp';
import LocalTexts from '../../HomepageTexts.json';

import { NewsAndToolsCardProps } from './SubComponents/NewsAndToolsCard/NewsAndToolsCard';

const LocalT = new MDText(LocalTexts);

export const NEWS_AND_TOOLS_TILES = {
  ADVERTISEMENT: 'advert',
  APP: 'app',
  CVT: 'cvt',
  ULEZ: 'ulez',
} as const;

export const newsAndToolsTilesContent: Omit<NewsAndToolsCardProps, 'onClick' | 'isSectionVisible'>[] = [
  {
    cta: LocalT.translate('NewsAndTools.app.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.app.imageAlt') as string,
      src: {
        avif: appAvif,
        png: appJpg,
        webp: appWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.app.label') as string,
    link: APP_URL,
    openInNewTab: true,
    tag: LocalT.translate('NewsAndTools.app.tag') as string,
    theme: 'blue',
    title: LocalT.translate('NewsAndTools.app.title') as string,
  },
  {
    cta: LocalT.translate('NewsAndTools.advert.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.advert.imageAlt') as string,
      src: {
        avif: advertAvif,
        png: advertJpg,
        webp: advertWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.advert.label') as string,
    link: YOUTUBE_EMBED_URL,
    openInNewTab: true,
    tag: LocalT.translate('NewsAndTools.advert.tag') as string,
    theme: 'purple',
    title: LocalT.translate('NewsAndTools.advert.title') as string,
  },
  {
    cta: LocalT.translate('NewsAndTools.guide.cta') as string,
    img: {
      alt: LocalT.translate('NewsAndTools.guide.imageAlt') as string,
      src: {
        avif: guidesAvif,
        png: guidesJpg,
        webp: guidesWebp,
      },
    },
    label: LocalT.translate('NewsAndTools.guide.label') as string,
    link: GUIDES_SELL_MY_CAR_PATH,
    tag: LocalT.translate('NewsAndTools.guide.tag') as string,
    theme: 'green',
    title: LocalT.translate('NewsAndTools.guide.title') as string,
  },
];
