import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import { RoundedSection } from '../../../common/RoundedSection/RoundedSection';
import { ExpandableSection } from '../../../fastlane/expandableSection/ExpandableSection';

import LocalTexts from './HomepageFAQTexts.json';

import styles from './HomepageFAQ.scss';

type Props = {
  readonly className?: string;
}

const LocalT = new MDText(LocalTexts);
const t = (key: string) => LocalT.translate(key) as string;

const TEXT = {
  imgAlt: t('imgAlt'),
  title: t('title'),
};

const faqContent = Object.values(LocalTexts.content);

export const HomepageFAQ = ({ className }: Props) => {
  const onRefTrigger = () => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.FAQ);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  return (
    <RoundedSection withTopLedge className={cx(styles.component, className)}>
      <Content ref={(ref: Element) => setRef(ref)} className={styles.faqContent} data-testid='homepageFAQ'>
        <ExpandableSection content={faqContent} title={TEXT.title} />
      </Content>
    </RoundedSection>
  );
};
