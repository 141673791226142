import React, {
  useEffect,
  useRef,
} from 'react';
import cx from 'classnames';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import VehicleImage from '../../../../../components/misc/VehicleImage/VehicleImage';
import { HeroFrameAnimation } from '../../../common/Hero/HeroFrameAnimation/HeroFrameAnimation';
import { useHeroHelpers } from '../../../common/Hero/Hooks/useHeroHelpers';

import { HomepageVRM } from './SubComponents/HomepageVRM/HomepageVRM';

import styles from './Hero.scss';

export type HeroProps = {
  subTitle?: string;
  title?: string;
};

export const Hero = ({ subTitle, title }: HeroProps) => {
  const container = useRef<HTMLInputElement>(null);

  const {
    cssVars,
    isAnimatingMileageTransition,
    isAnimatingVrmTransition,
    isMileageTransition,
    loading,
    loadingHandler,
    minHeight,
    prevIsAnimatingMileageTransition,
    setIsAnimatingMileageTransition,
    setIsAnimatingVrmTransition,
  } = useHeroHelpers({ animateFromHomePage: true, container });

  useEffect(() => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.HERO), []);

  const mileageTransitionStyles = {
    [styles.mileageTransition]: prevIsAnimatingMileageTransition || isMileageTransition,
  };

  return (
    <div className={cx(styles.homepageHeroWrapper)}>
      <div
        className={cx(styles.homepageHero, mileageTransitionStyles)}
      >
        <div className={styles.homepageContentWrapper}>
          <div
            className={styles.component}
            style={{ ['--hero-min-height' as string]: minHeight }}
          >
            <div ref={container} style={cssVars}>
              <HomepageVRM
                {...{
                  loadingHandler,
                  subTitle,
                  title,
                }}
                inProgress={isAnimatingVrmTransition || isAnimatingMileageTransition || Boolean(loading)}
              />
            </div>
          </div>
        </div>

        <div className={styles.homepageImageWrapper}>
          <VehicleImage
            name="hero_bmw_blue"
          />

          <svg className={cx(styles.desktopArrow, {
            [styles.animating]: isAnimatingVrmTransition || isAnimatingMileageTransition,
          })} fill="none" height="559" preserveAspectRatio="none" viewBox="0 0 485 559" width="485" xmlns="http://www.w3.org/2000/svg">
            <path d="M192.365 7.40829C190.209 2.88257 185.642 0 180.629 0H13C5.82031 0 0 5.8203 0 13V279.5V546C0 553.18 5.8203 559 13 559H180.629C185.642 559 190.209 556.117 192.365 551.591L319.336 285.079C321.021 281.542 321.021 277.433 319.336 273.896L192.365 7.40829Z" fill="var(--thc-color-primary-yellow)"/>
            <path d="M376.407 8.1694C374.432 3.23517 369.653 0 364.338 0H335C327.82 0 322 5.8203 322 13V546C322 553.18 327.82 559 335 559H364.338C369.652 559 374.432 555.765 376.407 550.83L483.067 284.318C484.308 281.217 484.308 277.758 483.067 274.657L376.407 8.1694Z" fill="var(--thc-color-primary-yellow)"/>
          </svg>

          {isAnimatingVrmTransition && (
            <HeroFrameAnimation
              isAnimating
              setIsAnimating={setIsAnimatingVrmTransition}
            />
          )}
        </div>
      </div>
      {isMileageTransition && (
        <HeroFrameAnimation
          isAnimating
          isMileageAnimation
          duration={750}
          setIsAnimating={setIsAnimatingMileageTransition}
        />
      )}
    </div>
  );
};
